<template>
    <v-container fluid>
        <v-alert
        dense
        outlined
        type="error"
        v-if="Object.keys(validation_errors).length > 0"
        >
            <ul>
                <li v-for="(errors, field) in validation_errors" :key="field">
                    {{ field }}
                    <ul>
                        <li v-for="(error, sl) in errors" :key="sl">
                            {{ error }}
                        </li>
                    </ul>
                </li>
            </ul>
        </v-alert>
        <v-form ref="incrementForm" class="custom-form" @submit.prevent="saveIncrement">
            <v-row dense>
                <v-col cols="12" 
                    md="3"
                    style="height: 272px"
                >
                    <v-row>
                        <v-col cols="12" class="py-1">
                            <v-menu 
                                max-width="290"
                                transition="scale-transition"
                                offset-y
                                v-model="menu"
                                :close-on-content-click="false"
                            >
                                <template v-slot:activator="{ on }">
                                    <v-text-field
                                        dense
                                        readonly
                                        outlined
                                        hide-details
                                        v-on="on"
                                        v-model="increment.increment_date"
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    no-title
                                    v-model="increment.increment_date"
                                    @input="menu = false"
                                ></v-date-picker>
                            </v-menu>
                        </v-col>
                    </v-row>
                    <v-combobox
                        label="Employee"
                        dense
                        outlined
                        hide-details
                        :items="employees"
                        v-model="selectedEmployee"
                        item-text="display_name"
                        item-value="id"
                        class="mb-1"
                        @focus="getEmployees"
                        :rules="[() => !!increment.employee_id || '']"
                        :error-messages="validation_errors.employee_id"
                    ></v-combobox>
                    <v-card :dark="selectedEmployee ? true : false" :color="selectedEmployee ? 'primary' : '#ECEFF1'" :elevation="1">
                        <v-card-text class="text-center pa-2">
                            <v-avatar
                                color="deep-purple darken-4"
                                size="120"
                                class="mb-4"
                            >
                                <v-img v-if="selectedEmployee" :src="$store.state.host+selectedEmployee.image"></v-img>
                                <v-img v-else src=""></v-img>
                            </v-avatar>
                            <h2 class="caption mb-0">
                                {{ selectedEmployee ? selectedEmployee.emp_id : 'Employee ID' }}
                            </h2>
                            <h1 class="subtitle-2">
                                {{ selectedEmployee ? selectedEmployee.name : 'Employee name'}}
                            </h1>
                            <p class="caption mb-0">
                                {{ selectedEmployee ? selectedEmployee.designation_name : 'Designation' }}, 
                                {{ selectedEmployee ? selectedEmployee.department_name : 'Department' }}
                            </p>
                            <p class="caption mb-0">
                                {{ selectedEmployee ? selectedEmployee.phone : 'Phone' }}
                            </p>
                        </v-card-text>
                    </v-card>
                </v-col>

                <v-col
                    cols="12"
                    sm="12"
                    md="4"
                >
                    <v-row>
                        <v-col cols="12" class="py-1">
                            <v-combobox
                                label="Increment By"
                                dense
                                outlined
                                hide-details
                                :items="employees"
                                v-model="selectedIncrementBy"
                                item-text="display_name"
                                item-value="id"
                                class="custom-form"
                                @focus="getEmployees"
                                :rules="[() => !!increment.increment_by || '']"
                                :error-messages="validation_errors.increment_by"
                            ></v-combobox>
                        </v-col>
                    </v-row>

                    <v-card :elevation="1">
                        <v-toolbar color="white" :elevation="1" height="30px">
                            <v-toolbar-title class="subtitle-2">Previous Information</v-toolbar-title>
                        </v-toolbar>

                        <v-card-text>
                            <v-row>
                                <v-col cols="5" class="py-1 caption">Gross Salary</v-col>
                                <v-col cols="3" class="py-0 pr-0"></v-col>
                                <v-col cols="4" class="py-0 pl-0">
                                    <strong style="float: right;">{{increment.previous_gross_salary}}</strong>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="5" class="py-1 caption">Basic Salary</v-col>
                                <v-col cols="3" class="py-0 pr-0"></v-col>
                                <v-col cols="4" class="py-0 pl-0">
                                    <strong style="float: right;">{{increment.previous_basic_salary}}</strong>
                                 </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="5" class="py-1 caption">House Rent</v-col>
                                <v-col cols="3" class="py-0 pr-0">
                                    <strong style="float: right;">{{increment.previous_house_rent_percent}}%</strong>
                                </v-col>
                                <v-col cols="4" class="py-0 pl-0">
                                    <strong style="float: right;">{{increment.previous_house_rent_amount}}</strong>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="5" class="py-1 caption">Med. Allowance</v-col>
                                <v-col cols="3" class="py-0 pr-0">
                                    <strong style="float: right;">{{increment.previous_medical_allowance_percent}}%</strong>
                                </v-col>
                                <v-col cols="4" class="py-0 pl-0">
                                    <strong style="float: right;">{{increment.previous_medical_allowance_amount}}</strong>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="5" class="py-1 caption">Mob. Allowance</v-col>
                                <v-col cols="3" class="py-0 pr-0">
                                    <strong style="float: right;">{{increment.previous_mobile_allowance_percent}}%</strong>
                                </v-col>
                                <v-col cols="4" class="py-0 pl-0">
                                    <strong style="float: right;">{{increment.previous_mobile_allowance_amount}}</strong>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="5" class="py-1 caption">Conveyance</v-col>
                                <v-col cols="3" class="py-0 pr-0">
                                    <strong style="float: right;">{{increment.previous_conveyance_allowance_percent}}%</strong>
                                </v-col>
                                <v-col cols="4" class="py-0 pl-0">
                                    <strong style="float: right;">{{increment.previous_conveyance_allowance_amount}}</strong>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="5" class="py-1 caption">Provident Fund</v-col>
                                <v-col cols="3" class="py-0 pr-0">
                                    <strong style="float: right;">{{increment.previous_provident_fund_percent}}%</strong>
                                </v-col>
                                <v-col cols="4" class="py-0 pl-0">
                                    <strong style="float: right;">{{increment.previous_provident_fund_amount}}</strong>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="5" class="py-1 caption">Others</v-col>
                                <v-col cols="3" class="py-0 pr-0">
                                    <strong style="float: right;">{{increment.previous_others_allowance_percent}}%</strong>
                                </v-col>
                                <v-col cols="4" class="py-0 pl-0">
                                    <strong style="float: right;">{{increment.previous_others_allowance_amount}}</strong>
                                </v-col>
                            </v-row>
                            
                        </v-card-text>
                    </v-card>
                </v-col>

                <v-col cols="12" md="5">
                    <v-row>
                        <v-col cols="12" class="py-1">
                            <v-text-field
                                dense
                                outlined
                                hide-details
                                label="Remark"
                                v-model="increment.remark"
                            >
                            </v-text-field>
                        </v-col>
                    </v-row>

                    <v-card :elevation="1">
                        <v-toolbar color="white" :elevation="1" height="30px">
                            <v-toolbar-title class="subtitle-2">Increment</v-toolbar-title>
                        </v-toolbar>

                        <v-card-text>
                            <v-row>
                                <v-col cols="4" class="py-1 caption">Gross Salary</v-col>
                                <v-col cols="8" class="py-0">
                                    <strong>{{increment.gross_salary}}</strong>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="4" class="py-1 caption">Basic Salary</v-col>
                                <v-col cols="3" class="py-0 pr-0">
                                    <v-text-field
                                        dense
                                        outlined
                                        hide-details
                                        suffix="%"
                                        v-model.number="increment.basic_salary_percent"
                                        id="basicSalaryPercent"
                                        @keyup="calculateGrossSalary($event)"
                                        :rules="[(v) => !!v]"
                                        :error-messages="validation_errors.basic_salary_percent"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="5" class="py-0 pl-0">
                                    <v-text-field
                                        dense
                                        outlined
                                        hide-details
                                        v-model.number="increment.basic_salary"
                                        id="basicSalary"
                                        @keyup="calculateGrossSalary($event)"
                                        :rules="[(v) => !!v]"
                                        :error-messages="validation_errors.basic_salary"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="4" class="py-1 caption">House Rent</v-col>
                                <v-col cols="3" class="py-0 pr-0">
                                    <v-text-field
                                        dense
                                        outlined
                                        hide-details
                                        suffix="%"
                                        v-model.number="increment.house_rent_percent"
                                        id="houseRentPercent"
                                        @keyup="calculateGrossSalary($event)"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="5" class="py-0 pl-0">
                                    <v-text-field
                                        dense
                                        outlined
                                        hide-details
                                        v-model.number="increment.house_rent_amount"
                                        @keyup="calculateGrossSalary($event)"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="4" class="py-1 caption">Med. Allowance</v-col>
                                <v-col cols="3" class="py-0 pr-0">
                                    <v-text-field
                                        dense
                                        outlined
                                        hide-details
                                        suffix="%"
                                        v-model.number="increment.medical_allowance_percent"
                                        id="medicalAllowancePercent"
                                        @keyup="calculateGrossSalary($event)"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="5" class="py-0 pl-0">
                                    <v-text-field
                                        dense
                                        outlined
                                        hide-details
                                        v-model.number="increment.medical_allowance_amount"
                                        @keyup="calculateGrossSalary($event)"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="4" class="py-1 caption">Mob. Allowance</v-col>
                                <v-col cols="3" class="py-0 pr-0">
                                    <v-text-field
                                        dense
                                        outlined
                                        hide-details
                                        suffix="%"
                                        v-model.number="increment.mobile_allowance_percent"
                                        id="mobileAllowancePercent"
                                        @keyup="calculateGrossSalary($event)"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="5" class="py-0 pl-0">
                                    <v-text-field
                                        dense
                                        outlined
                                        hide-details
                                        v-model.number="increment.mobile_allowance_amount"
                                        @keyup="calculateGrossSalary($event)"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="4" class="py-1 caption">Conveyance</v-col>
                                <v-col cols="3" class="py-0 pr-0">
                                    <v-text-field
                                        dense
                                        outlined
                                        hide-details
                                        suffix="%"
                                        v-model.number="increment.conveyance_allowance_percent"
                                        id="conveyanceAllowancePercent"
                                        @keyup="calculateGrossSalary($event)"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="5" class="py-0 pl-0">
                                    <v-text-field
                                        dense
                                        outlined
                                        hide-details
                                        v-model.number="increment.conveyance_allowance_amount"
                                        @keyup="calculateGrossSalary($event)"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="4" class="py-1 caption">Provident Fund</v-col>
                                <v-col cols="3" class="py-0 pr-0">
                                    <v-text-field
                                        dense
                                        outlined
                                        hide-details
                                        suffix="%"
                                        v-model.number="increment.provident_fund_percent"
                                        id="providentFundPercent"
                                        @keyup="calculateGrossSalary($event)"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="5" class="py-0 pl-0">
                                    <v-text-field
                                        dense
                                        outlined
                                        hide-details
                                        v-model.number="increment.provident_fund_amount"
                                        @keyup="calculateGrossSalary($event)"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="4" class="py-1 caption">Others</v-col>
                                <v-col cols="3" class="py-0 pr-0">
                                    <v-text-field
                                        dense
                                        outlined
                                        hide-details
                                        suffix="%"
                                        v-model.number="increment.others_allowance_percent"
                                        id="othersAllowancePercent"
                                        @keyup="calculateGrossSalary($event)"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="5" class="py-0 pl-0">
                                    <v-text-field
                                        dense
                                        outlined
                                        hide-details
                                        v-model.number="increment.others_allowance_amount"
                                        @keyup="calculateGrossSalary($event)"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" md="12" class="text-right">
                    <v-btn height="30px" dark color="light-blue darken-2 mr-1" :elevation="1" type="submit" :loading="loading">Save</v-btn>
                    <v-btn height="30px" dark color="deep-orange" :elevation="1" @click="resetForm">Cancel</v-btn>
                </v-col>
            </v-row>
        </v-form>

        <v-row v-if="increments.length > 0">
            <v-col cols="12">
                <v-btn
                    color="primary"
                    class="white--text"
                    small
                    dense
                    @click="print"
                >
                    <v-icon left small dense> mdi-printer </v-icon>
                    Print
                </v-btn>
            </v-col>
            <v-col cols="12" class="pb-0" id="reportContent">
                <v-simple-table dense class="record_table">
                    <thead>
                        <tr class="record_heading_tr">
                            <th>SL.</th>
                            <th>Date</th>
                            <th>Previous Basic Salary</th>
                            <th>Previous Gross Salary</th>
                            <th>Increment (%)</th>
                            <th>Basic Salary</th>
                            <th>Gross Salary</th>
                            <th>Increment By</th>
                            <th>Remark</th>
                            <th>Option</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(record, sl) in increments" :key="sl">
                            <td>{{++sl}}</td>
                            <td>{{record.increment_date}}</td>
                            <td>{{record.previous_basic_salary}}</td>
                            <td>{{record.previous_gross_salary}}</td>
                            <td>{{record.basic_salary_percent}}%</td>
                            <td>{{record.basic_salary}}</td>
                            <td>{{record.gross_salary}}</td>
                            <td style="text-align: left !important;">{{record.increment_by_name}}</td>
                            <td style="text-align: left !important;">{{record.remark}}</td>
                            <td>
                                <v-tooltip bottom v-if="$store.state['user'].role != 'User'">
                                    <template v-slot:activator="{ on }">
                                        <v-icon
                                            small
                                            @click="editIncrement(record)"
                                            color="primary"
                                            v-on="on"
                                            >mdi-circle-edit-outline</v-icon
                                        >
                                    </template>
                                    <span>Edit</span>
                                </v-tooltip>
                                <v-tooltip bottom v-if="$store.state['user'].role == 'Admin'">
                                    <template v-slot:activator="{ on }">
                                        <v-icon
                                            small
                                            @click="deleteIncrement(record)"
                                            color="error"
                                            v-on="on"
                                            >mdi-delete-circle-outline</v-icon
                                        >
                                    </template>
                                    <span>Delete</span>
                                </v-tooltip>
                            </td>
                        </tr>
                    </tbody>
                </v-simple-table>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import utility from '../../mixins/utility.mixin';
export default {
    mixins: [utility],
    data(){
        return {
            employees: [],
            selectedEmployee: null,
            selectedIncrementBy: null,
            validation_errors : {},
            increment: {
                id            : null,
                employee_id   : null,
                increment_date: this.toISOLocal().substr(0, 10),
                increment_by  : null,
                remark        : '',

                previous_gross_salary                : '',
                previous_basic_salary                : '',
                previous_house_rent_percent          : '',
                previous_house_rent_amount           : '',
                previous_medical_allowance_percent   : '',
                previous_medical_allowance_amount    : '',
                previous_mobile_allowance_percent    : '',
                previous_mobile_allowance_amount     : '',
                previous_conveyance_allowance_percent: '',
                previous_conveyance_allowance_amount : '',
                previous_provident_fund_percent      : '',
                previous_provident_fund_amount       : '',
                previous_others_allowance_percent    : '',
                previous_others_allowance_amount     : '',

                gross_salary                : '',
                basic_salary_percent        : '',
                basic_salary                : '',
                house_rent_percent          : '',
                house_rent_amount           : '',
                medical_allowance_percent   : '',
                medical_allowance_amount    : '',
                mobile_allowance_percent    : '',
                mobile_allowance_amount     : '',
                conveyance_allowance_percent: '',
                conveyance_allowance_amount : '',
                provident_fund_percent      : '',
                provident_fund_amount       : '',
                others_allowance_percent    : '',
                others_allowance_amount     : '',
            },
            increments: [],
            menu: false,
            loading: false,
        }
    },
    watch: {
        selectedEmployee(e){
            this.increment.employee_id       = null;
            this.clearAmount();
            this.getIncrements();
            if(!e || e.id == undefined){
                return;
            }
            this.increment.employee_id     = e.id;
            this.distributeAmount(e);
        },
        selectedIncrementBy(e){
            this.increment.increment_by = null;
            if(!e || e.id == undefined){
                return;
            }
            this.increment.increment_by = e.id;
        },
    },
    methods: {
        distributeAmount(e){
            this.increment.previous_gross_salary                 = e.gross_salary;
            this.increment.previous_basic_salary                 = e.basic_salary;
            this.increment.previous_house_rent_percent           = e.house_rent_percent;
            this.increment.previous_house_rent_amount            = e.house_rent_amount;
            this.increment.previous_medical_allowance_percent    = e.medical_allowance_percent;
            this.increment.previous_medical_allowance_amount     = e.medical_allowance_amount;
            this.increment.previous_mobile_allowance_percent     = e.mobile_allowance_percent;
            this.increment.previous_mobile_allowance_amount      = e.mobile_allowance_amount;
            this.increment.previous_conveyance_allowance_percent = e.conveyance_allowance_percent;
            this.increment.previous_conveyance_allowance_amount  = e.conveyance_allowance_amount;
            this.increment.previous_provident_fund_percent       = e.provident_fund_percent;
            this.increment.previous_provident_fund_amount        = e.provident_fund_amount;
            this.increment.previous_others_allowance_percent     = e.others_allowance_percent;
            this.increment.previous_others_allowance_amount      = e.others_allowance_amount;

            this.increment.house_rent_percent           = e.house_rent_percent;
            this.increment.medical_allowance_percent    = e.medical_allowance_percent;
            this.increment.mobile_allowance_percent     = e.mobile_allowance_percent;
            this.increment.conveyance_allowance_percent = e.conveyance_allowance_percent;
            this.increment.provident_fund_percent       = e.provident_fund_percent;
            this.increment.others_allowance_percent     = e.others_allowance_percent;
        },
        clearAmount(){
            this.increment.previous_gross_salary                 = '';
            this.increment.previous_basic_salary                 = '';
            this.increment.previous_house_rent_percent           = '';
            this.increment.previous_house_rent_amount            = '';
            this.increment.previous_medical_allowance_percent    = '';
            this.increment.previous_medical_allowance_amount     = '';
            this.increment.previous_mobile_allowance_percent     = '';
            this.increment.previous_mobile_allowance_amount      = '';
            this.increment.previous_conveyance_allowance_percent = '';
            this.increment.previous_conveyance_allowance_amount  = '';
            this.increment.previous_provident_fund_percent       = '';
            this.increment.previous_provident_fund_amount        = '';
            this.increment.previous_others_allowance_percent     = '';
            this.increment.previous_others_allowance_amount      = '';

            this.increment.gross_salary                 = '';
            this.increment.basic_salary_percent         = '';
            this.increment.basic_salary                 = '';
            this.increment.house_rent_percent           = '';
            this.increment.house_rent_amount            = '';
            this.increment.medical_allowance_percent    = '';
            this.increment.medical_allowance_amount     = '';
            this.increment.mobile_allowance_percent     = '';
            this.increment.mobile_allowance_amount      = '';
            this.increment.conveyance_allowance_percent = '';
            this.increment.conveyance_allowance_amount  = '';
            this.increment.provident_fund_percent       = '';
            this.increment.provident_fund_amount        = '';
            this.increment.others_allowance_percent     = '';
            this.increment.others_allowance_amount      = '';
        },
        calculateGrossSalary(e) {
            if(e.target.id == 'basicSalaryPercent'){
                this.increment.basic_salary = (((+this.increment.basic_salary_percent + 100) * this.increment.previous_basic_salary) / 100).toFixed(2);
            }else if (e.target.id == 'basicSalary'){
                this.increment.basic_salary_percent = (((+this.increment.basic_salary - +this.increment.previous_basic_salary) / this.increment.previous_basic_salary) * 100).toFixed(2);
            }

            let basicSalary = this.increment.basic_salary;
            if (basicSalary == 0) return;

            if (e.target.id == "houseRentPercent" || e.target.id == 'basicSalary' || e.target.id == 'basicSalaryPercent') {
                this.increment.house_rent_amount = ((basicSalary * this.increment.house_rent_percent) / 100).toFixed(2);
            } else {
                this.increment.house_rent_percent = ((this.increment.house_rent_amount / basicSalary) * 100).toFixed(2);
            }

            if (e.target.id == "medicalAllowancePercent" || e.target.id == 'basicSalary' || e.target.id == 'basicSalaryPercent') {
                this.increment.medical_allowance_amount = ((basicSalary * this.increment.medical_allowance_percent) / 100).toFixed(2);
            } else {
                this.increment.medical_allowance_percent = ((this.increment.medical_allowance_amount / basicSalary) * 100).toFixed(2);
            }

            if (e.target.id == "mobileAllowancePercent" || e.target.id == 'basicSalary' || e.target.id == 'basicSalaryPercent') {
                this.increment.mobile_allowance_amount = ((basicSalary * this.increment.mobile_allowance_percent) / 100).toFixed(2);
            } else {
                this.increment.mobile_allowance_percent = ((this.increment.mobile_allowance_amount / basicSalary) * 100).toFixed(2);
            }

            if (e.target.id == "conveyanceAllowancePercent" || e.target.id == 'basicSalary' || e.target.id == 'basicSalaryPercent') {
                this.increment.conveyance_allowance_amount = ((basicSalary * this.increment.conveyance_allowance_percent) / 100).toFixed(2);
            } else {
                this.increment.conveyance_allowance_percent = ((this.increment.conveyance_allowance_amount / basicSalary) * 100).toFixed(2);
            }

            if (e.target.id == "providentFundPercent" || e.target.id == 'basicSalary' || e.target.id == 'basicSalaryPercent') {
                this.increment.provident_fund_amount = ((basicSalary * this.increment.provident_fund_percent) / 100).toFixed(2);
            } else {
                this.increment.provident_fund_percent = ((this.increment.provident_fund_amount / basicSalary) * 100).toFixed(2);
            }

            if (e.target.id == "othersAllowancePercent" || e.target.id == 'basicSalary' || e.target.id == 'basicSalaryPercent') {
                this.increment.others_allowance_amount = ((basicSalary * this.increment.others_allowance_percent) / 100).toFixed(2);
            } else {
                this.increment.others_allowance_percent = ((this.increment.others_allowance_amount / basicSalary) * 100).toFixed(2);
            }

            let gross_salary = +this.increment.house_rent_amount + +this.increment.medical_allowance_amount;
            gross_salary += +this.increment.mobile_allowance_amount + +this.increment.conveyance_allowance_amount;
            gross_salary += +this.increment.others_allowance_amount + +basicSalary;

            this.increment.gross_salary = +gross_salary.toFixed(2);
        },
        async getIncrements(){
            this.increments = [];
            if(this.selectedEmployee && this.selectedEmployee.id != undefined){
                this.increments = await this.$store.dispatch('increment/getIncrements',{
                    apiParams: {
                        employee_id: this.selectedEmployee.id
                    },
                    options: {
                        returnData: true,
                    }
                });
            }
        },
        async getEmployees(){
            this.employees = await this.$store.dispatch('employee/getEmployees', {
                apiParams: {},
                options: {
                    returnData: true
                }
            });
        },
        async saveIncrement(){

            if(!this.$refs.incrementForm.validate()){
                this.$store.dispatch('snackbar/error', 'Fill red marked fields');
                return;
            }

            this.loading = true;
            let url = "/add_increment";

            if(this.increment.id){
                url = '/update_increment';
            }
            let data = {url, increment: this.increment}

            let resObj = await this.$store.dispatch('increment/saveIncrement', data);

            if(resObj.hasOwnProperty("validation_errors")){
                this.validation_errors = resObj.validation_errors;
            }

            this.loading = false;
            if(resObj.success) {
                this.resetForm();
            }
        },
        editIncrement(increment){

            this.selectedIncrementBy = {
                id : increment.increment_by,
                display_name: increment.increment_by_name,
            }

            let keys = Object.keys(this.increment);
            keys.forEach(key => {
                this.increment[key] = increment[key];
            })
        },
        async deleteIncrement(increment){
            if (!confirm("Are you sure?")) return;
            await this.$store.dispatch("increment/deleteIncrement", increment.id);
            this.resetForm();
        },
        resetForm(){
            this.selectedEmployee    = null;
            this.selectedIncrementBy = null;

            this.validation_errors = {};

            this.increment = {
                id            : null,
                employee_id   : null,
                increment_date: this.toISOLocal().substr(0, 10),
                increment_by  : null,
                remark        : '',

                previous_gross_salary                : '',
                previous_basic_salary                : '',
                previous_house_rent_percent          : '',
                previous_house_rent_amount           : '',
                previous_medical_allowance_percent   : '',
                previous_medical_allowance_amount    : '',
                previous_mobile_allowance_percent    : '',
                previous_mobile_allowance_amount     : '',
                previous_conveyance_allowance_percent: '',
                previous_conveyance_allowance_amount : '',
                previous_provident_fund_percent      : '',
                previous_provident_fund_amount       : '',
                previous_others_allowance_percent    : '',
                previous_others_allowance_amount     : '',

                gross_salary                : '',
                basic_salary_percent        : '',
                basic_salary                : '',
                house_rent_percent          : '',
                house_rent_amount           : '',
                medical_allowance_percent   : '',
                medical_allowance_amount    : '',
                mobile_allowance_percent    : '',
                mobile_allowance_amount     : '',
                conveyance_allowance_percent: '',
                conveyance_allowance_amount : '',
                provident_fund_percent      : '',
                provident_fund_amount       : '',
                others_allowance_percent    : '',
                others_allowance_amount     : '',
            };

            this.$refs.incrementForm.resetValidation();
        },
        async print(){
            let title = 'Salary Increment Record of ';
            if(this.selectedEmployee && this.selectedEmployee.id != undefined){
                title += this.selectedEmployee.name;
            }

            let company_profile = this.$store.getters['company/company'];

            // Get HTML to print from element
            const prtHtml = document.getElementById('reportContent').innerHTML;

            // Get all stylesheets HTML
            let stylesHtml = '';
            for (const node of [...document.querySelectorAll('link[rel="stylesheet"], style')]) {
                stylesHtml += node.outerHTML;
            }

            // Open the print window
            // const WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');
            const WinPrint = window.open('', 'PRINT', `height=${screen.height}, width=${screen.width}`);
            WinPrint.document.write(`<!DOCTYPE html>
            <html>
            <head>
                ${stylesHtml}
            </head>
            <body>
                <div data-app="true" class="v-application v-application--is-ltr theme--light" id="app">
                    <div class="container container--fluid">
                        <div class="row row--dense">
                            <div class="col-2 px-2">
                                <img style="width: 100%; height: 50px;" src="${this.$store.state.host+company_profile.logo}">
                            </div>
                            <div class="col-10 px-2 text-center">
                                <span class="font-weight-black">
                                    ${company_profile.name}
                                </span>
                                <br>
                                <span>${company_profile.address}</span>
                                <br>
                                <span>
                                    Email:${company_profile.email}, Contact:${company_profile.phone}
                                </span>
                            </div>
                            <div class="col-12 px-2 text-center">
                                <p class="font-weight-black custom_header">
                                    ${title}
                                </p>
                            </div>
                            <div class="col col-12 px-2">
                                ${prtHtml}
                            </div>
                        </div>
                    </div>
                </div>
            </body>
            </html>`);

            WinPrint.document.head.innerHTML += `
                <style>
                    .v-data-table__wrapper{
                        overflow: unset;
                    }
                    
                    .record_heading_tr th{
                        text-align: center !important;
                    }
                    .custom_header{
                        background-color: #0000001f;
                        border-radius: 4px;
                        margin-bottom: 5px !important;
                    }
                    tr:hover{
                        background: unset !important;
                    }
                    .record_heading_tr:hover{
                        background: #4caf7773 !important;
                    }
                    @media print {
                        table.record_table { page-break-after:auto }
                        table.record_table tr    { page-break-inside:avoid; page-break-after:auto }
                        table.record_table td    { page-break-inside:avoid; page-break-after:auto }
                        table.record_table thead { display:table-header-group }
                        table.record_table tfoot { display:table-footer-group }
                    }
                </style>
            `;

            let rows = WinPrint.document.querySelectorAll('.record_table tr');
            rows.forEach(row => {
                row.lastChild.remove();
            })

            WinPrint.focus();
            WinPrint.document.close();
            await new Promise(resolve => setTimeout(resolve, 2000));
            WinPrint.print();
            WinPrint.close();
        }
    },
}
</script>

<style>
.record_table{
    margin-bottom: 5px;
}
.record_table table, .record_table th, .record_table td {
    border: 1px solid #dee2e6;
    border-collapse: collapse;
    padding: 0 !important;
    
}
.record_table th, .record_table td {
    padding: 2px !important;
    font-size: 11px !important;
    text-align: center !important;
    height: 0 !important;
}
.record_heading_tr{
    background: #4caf7773;
}
</style>